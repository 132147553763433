<template>
    <div id="demo-card-analytics">
        <!-- ROW 1 -->
        <div class="vx-row">
            <!-- SESSIONS BY DEVICES -->
            <div class="vx-col w-full lg:w-1/2 mb-base">
                <vx-card :title="title">
<vs-button icon-pack="feather" style="margin-left:30%" @click="$router.push(router)" icon="icon-chevrons-right" icon-after class="shadow-md-3">{{routerText}}</vs-button>
                    <!-- CHART -->
                    <div slot="no-body">
                        <vue-apex-charts type="donut" height="340" class="mb-12 mt-4" :options="products_chart.productsChartOptions" :series="products_chart.series" />
                        <ul style="margin-left:30%">
                            <li v-for="product in products_chart.analyticsData" :key="product.name" class="flex mb-3">
                       <span class="inline-block h-4 w-4 rounded-full mr-2 bg-white border-3 border-solid"></span>
                               <!--  :class="`border-${products_chart.productsChartOptions.colors}`" -->
                                <span class="inline-block font-semibold">{{product.category}}</span>
                                <span class="ml-4 inline-block">{{ product.products }} unidades ({{ product.percentage }}%)</span>
                           </li>
                        </ul>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    props: {
    title:{type:String, required:true},
    products_chart:{type:Object, required:true},
    router:{type:String, required:true},
    routerText:{type:String, required:true},
 /*    icon:{type:String, required:true}, */


  },
  data () {
    return {
 /*      sessionsData: {}, */

    }
  },
  components: {
    VueApexCharts,
  },
  created () {
/*     // Sessions By Device
    this.$http.get('/api/card/card-analytics/session-by-device')
      .then((response) => { this.sessionsData = response.data })
      .catch((error) => { console.log(error) })
 */
  }
}
</script>

<style lang="scss">
#demo-card-analytics {
    .tasks-today-container {
        .tasks-today__task {
            transition: background .15s ease-out;
            .tasks-today__actions {
                display: none;
            }

            &:hover {
                background: rgba(var(--vs-primary),.04) ;
                .tasks-today__actions {
                    display: flex;
                }
            }
        }
    }
}
</style>
