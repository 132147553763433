<template>
      <vx-card class="vx-col">
        <vs-button slot="no-body" @click="$router.push(router)" class=" w-full text-center bg-danger-gradient greet-user">
          <feather-icon :icon="icon" class="p-6 mb-8 bg-danger inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
          <h1 class="mb-6 text-white">$ {{statistic}}</h1>
          <p class="text-white">{{description}}</p>
        </vs-button>
      </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
import VxTimeline from '@/components/timeline/VxTimeline'

export default {
   props: {
        icon: {
            type: String,
            required: true
        },
        statistic: {
 
            required: true,
        },
        description: {
            type: String,
            required:true
        },
        router:{
          type:String,
          required:true
        }
   },

  data () {
    return {
    }
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    VxTimeline
  },
  created () {}
}
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
</style>