<template>
  <div>
    <div class="vx-row">
 <!-- Total de cafeterias -->
      <div v-if="$acl.check('admin')" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <statistics-card
          class="mb-base"
          icon="HomeIcon"
          :statistic="total_institutions"
          statisticTitle="Total de cafeterías existentes"
          router="/institutions"
          hideChart
        ></statistics-card>
      </div>
<!-- Total de cafeterias administradas -->
      <div v-if="$acl.check('owner')" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <statistics-card
          class="mb-base"
          icon="AirplayIcon"
          :statistic="admin_statistics"
          statisticTitle="Cafeterias Administradas"
          router="/institutions/owner"
          hideChart
        ></statistics-card>
      </div>
<!-- Total de estudiantes + acudientes -->
      <div v-if="$acl.check('admin') || $acl.check('owner') || $acl.check('manager')" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <statistics-card
          class="mb-base"
          icon="UsersIcon"
          :statistic="tiptap_users.guardians + tiptap_users.students"
          :statisticTitle="(tiptap_users.guardians > 0 || tiptap_users.students > 0) ? tiptap_users.guardians + ' Acudiente' + (tiptap_users.guardians > 1 ? 's' : '') + ' + ' + tiptap_users.students + ' Estudiante' + (tiptap_users.students > 1 ? 's' : '') : 'No hay cuentas registradas aún'"
          router="/reports/users/tiptap"
          hideChart
        ></statistics-card>
      </div>
<!-- Ventas reversadas -->
      <div v-if="$acl.check('manager')" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <statistics-card
          class="mb-base"
          icon="XSquareIcon"
          :statistic="reverse"
          :statisticTitle="'Ventas Reversadas ' + capitalize(sales_chart.series[0].name)"
          router="/reports/canceled-sales"
          hideChart
        ></statistics-card>
      </div>
      <!-- Total a pagar -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <dashboard-analytics
          v-if="$acl.check('admin')"
          class="mb-base"
          icon="AlertTriangleIcon"
          :statistic="((charge_total) + (pay_total)).toFixed(2)"
          description="Total a Pagar a Administradores"
          router="/payments/pending"
          hideChart
        ></dashboard-analytics>
        <!-- Total a cobrar -->
        <dashboard-analytics
          v-if="$acl.check('owner')"
          class="mb-base"
          icon="AlertTriangleIcon"
          :statistic="(charge_total).toFixed(2)"
          description="Total a Cobrar"
          router="/requestsPay"
          hideChart
        ></dashboard-analytics>
        <!-- Total a cobrar Superadmin-->
        <dashboard-analytics
          v-if="$acl.check('superadmin')"
          class="mb-base"
          icon="AlertTriangleIcon"
          :statistic="(charge_total).toFixed(2)"
          description="Total a Cobrar"
          router="/sa/requestsPay"
          hideChart
        ></dashboard-analytics>
      </div>
      <!-- Total a pagar a Super Admin-->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <dashboard-analytics
            v-if="$acl.check('admin')"
            class="mb-base"
            icon="AlertTriangleIcon"
            :statistic="charge_total_sa.toFixed(2)"
            description="Total a Pagar a SuperAdmin"
            router="/payments/pending"
            hideChart
          ></dashboard-analytics>
      </div>
    </div>

<!-- VENTAS TIPTAP -->
    <div class="vx-row" v-if="!$acl.check('school')">
      <div class="vx-col w-full">
        <vx-card title="Ventas Tiptap">
          <template slot="actions"></template>
          <div slot="no-body" class="p-6 pb-0">

            <div class="flex" v-if="sales_chart.analyticsData">
                <div  class="mr-6">
                    <p class="mb-1 font-semibold capitalize">{{ sales_chart.series[0].name }}</p>
                    <p class="text-3xl text-success"><sup class="text-base mr-1">$</sup>{{ sales_chart.analyticsData.thisMonth.toFixed(2) }}</p>
                </div>
                <div class="mr-6">
                    <p class="mb-1 font-semibold capitalize">{{ sales_chart.series[1].name }}</p>
                    <p class="text-3xl"><sup class="text-base mr-1">$</sup>{{ sales_chart.analyticsData.lastMonth.toFixed(2) }}</p>
                </div>
                <div class="mr-6">
                    <p class="mb-1 font-semibold">Variación</p>
                    <p class="text-3xl"><sup class="text-base mr-1">%</sup>{{sales_chart.analyticsData.thisMonth > 0 && sales_chart.analyticsData.lastMonth > 0 ?((sales_chart.analyticsData.thisMonth - sales_chart.analyticsData.lastMonth) / sales_chart.analyticsData.lastMonth * 100).toFixed(1):0}}
                    <feather-icon :icon="(sales_chart.analyticsData.thisMonth - sales_chart.analyticsData.lastMonth) < 0 ? 'ArrowDownIcon' : 'ArrowUpIcon'" :svgClasses="[(sales_chart.analyticsData.thisMonth - sales_chart.analyticsData.lastMonth) < 0 ? 'text-danger' : 'text-success'  ,'stroke-current h-4 w-4 mb-1 mr-1']"></feather-icon></p>
                </div>
                <div class="justify-center responsive-fix">
                  <vs-button icon-pack="feather" @click="$router.push('/reports/sales')" icon="icon-chevrons-right" icon-after class="shadow-md-3">Ver detalles</vs-button>
                </div>
            </div>

            <vue-apex-charts
                type=line
                height=266
                :options="sales_chart.chartOptions"
                :series="sales_chart.series" />
          </div>
      </vx-card>
      </div>
    </div>

<!-- Productos y categorias -->
 <div class="vx-col w-full" style="padding-top:30px">
    <card-analytics
      class="mb-base"
      router="/products"
      routerText="Ver detalles"
      title="Productos por Categoría"
      :products_chart="products_chart"
      hideChart
      v-if="$acl.check('manager') && products_total > 0"
    ></card-analytics>
    </div>

  </div>
</template>
<script>
import StatisticsCard from "@/components/statistics-cards/StatisticsCard.vue";
import CardAnalytics from "@/components/analytics/CardAnalytics.vue";
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import analyticsData from './reports/sales/data/analytics.js'
import dashboardAnalytics from "@/components/analytics/dashboardAnalytics.vue";
import VueApexCharts from "vue-apexcharts";
import chartConfigs from "./chartConfigs.js";
import _color from "@/assets/utils/color.js";
import moduleDataList from "@/store/data-list/moduleDataList.js";
import moduleDataListCategories from "@/store/data-list-categories/moduleDataListCategories.js";
import reportModule from "@/store/report/moduleReport.js";
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js"

export default {
  props: {
    icon: {
      type: String,
    },
    statistic: {
      type: Number,
    },
    statisticTitle: {
      type: String,
    },
    chartData: {},
    color: {
      type: String,
      default: "primary",
    },
    colorTo: {
      type: String,
    },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    hideChart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      from: new Date(new Date().getFullYear(), new Date().getMonth()-1, 1),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),

      sales_chart: {
        analyticsData: {
          thisMonth: 0,
          lastMonth: 0
        },
        series: [
          {
            name: "Este mes", //"Octubre",
            data: [], //[450, 470, 448, 475, 455, 480, 465, 486]
          },
          {
            name: "Mes anterior", //"Septiembre",
            data: [], //[460, 480, 455, 466, 445, 465, 450, 470]
          }
        ],
        chartOptions: {
            chart: {
                toolbar: {
                  show: true,
                  tools: {
                    reset: true,
                    zoomin: false,
                    zoomout: false,
                    download: false,
                    pan: false,
                    selection: false,
                  }
                },
                dropShadow: {
                  enabled: true,
                  top: 5,
                  left: 0,
                  blur: 4,
                  opacity: 0.10,
                },
            },
            stroke: {
                curve: 'smooth',
                dashArray: [0, 8],
                width: [4, 2],
            },
            grid: {
                borderColor: '#e7e7e7',
            },
            legend: {
                show: false,
            },
            colors: ['#7367F0', '#b8c2cc'],
            // fill: {
            //     type: 'gradient',
            //     gradient: {
            //         shade: 'dark',
            //         inverseColors: false,
            //         gradientToColors: ['#7367F0', '#b8c2cc'],
            //         shadeIntensity: 1,
            //         type: 'horizontal',
            //         opacityFrom: 1,
            //         opacityTo: 1,
            //         stops: [0, 100, 100, 100]
            //     },
            // },
            markers: {
                size: 0,
                hover: {
                    size: 5
                }
            },
            xaxis: {
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    }
                },
                axisTicks: {
                    show: false,
                },
                categories: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
                axisBorder: {
                    show: false,
                },
            },
            yaxis: {
                tickAmount: 5,
                labels: {
                    style: {
                        cssClass: 'text-grey fill-current',
                    },
                    formatter: function(val) {
                        return val > 999 ? (val / 1000).toFixed(1) + 'k' : val;
                    }
                }
            },
            tooltip: {
                x: { show: false }
            }
        }
    },
      analyticsData: analyticsData,
       saleTypesStatistics: [],

      admin_statistics:0,
      reverse:0,
      tiptap_users: {
        guardians: 0,
        students: 0
      },
      pay_total:0,
      charge_total:0,
      charge_total_sa: 0,
      products_total:0,
      sales_total:0,
      total_institutions:0,


      products_chart: {
        analyticsData: [],
        series: [],

        productsChartOptions: {
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          chart: {
            offsetY: 30,
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          stroke: { width: 0 },
          colors: ["#7961F9", "#FF9F43", "#EA5455", "#28C76F"],
          fill: {
            type: "gradient",
            gradient: {
              gradientToColors: [
                "#A9A2F6",
                "#ffc085",
                "#F97794",
                "#55DD92",
                "#A9A2F6",
                "#ffc085",
                "#F97794",
              ],
            },
          },
        },
      },
    };
  },
  watch: {

    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex(this.color) } },
      });
    },
  },
  computed: {
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
  },
  methods: {
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf());
    },
    capitalize(str) {
      return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
    },
    getHex(color) {
      if (_color.isColor(color)) {
        let rgb = window.getComputedStyle(document.documentElement).getPropertyValue(`--vs-${color}`);
        rgb = rgb.split(",");
        return `#${((1 << 24) + (Number(rgb[0]) << 16) + (Number(rgb[1]) << 8) + Number(rgb[2])).toString(16).slice(1)}`;
      }
      return color;
    },
    gradientToColor(color) {
      const gradientToColors = {primary: "#A9A2F6",success: "#55DD92",warning: "#ffc085",danger: "#F97794",};
      return gradientToColors[color] ? gradientToColors[color] : gradientToColors["primary"];
    },
    getLastMonthDates() {
      return {from: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0], to: new Date().toISOString().split('T')[0]};
    },
    setProductsChartData() {
      this.products_chart.analyticsData = this.$store.getters["dataList/getProductsStatistics"];
      this.products_total = this.products_chart.analyticsData.reduce((total, p) => total + p.products, 0);
      this.products_chart.series = this.products_chart.analyticsData.map((p) => { return p.products;});
      this.products_chart.productsChartOptions.labels = this.products_chart.analyticsData.map((c) => { return c.category;});
    },
    setsalesChartData() {
      let statistics = this.$store.getters["reportModule/getSalesTiptap"];
       this.sales_chart.analyticsData = statistics.analyticsData;
       this.sales_chart.series = statistics.series;
    },
    setInstitutionCharData(){
      this.total_institutions = this.$store.getters["dataListInstitutions/getInstitutions"].length
    },
    setReversesChartData(){
      this.reverse = this.$store.getters["reportModule/getnumbercanceled"];
    },
    setUsersChartData(){
      if(this.$acl.check('manager'))
        this.tiptap_users = this.$store.getters["reportModule/getnumberUsersActive"];
      else
        this.tiptap_users = this.$store.getters["reportModule/getnumberUsers"];
    },
     setPaymentsChartData(){
      this.pay_total = this.$store.getters["reportModule/getpayments"];
      if(this.$acl.check('admin'))
        this.pay_total += this.$store.getters["reportModule/getServicePayments"]
    },
    setChargeChartData(){
      this.charge_total = this.$store.getters["reportModule/getcharge"];
    },
    setChargeSaChartData(){
      this.charge_total_sa = this.$store.getters["reportModule/getchargesa"]
    },
    getReverses() {
      // REVERSE (cantidad de reversadas)
      this.$store.dispatch("reportModule/getReverse", {id: this.$store.state.AppActiveUser.institution.id, dates: this.getLastMonthDates()})
      .then(() => {
        this.setReversesChartData();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getSales(admin = false) {
      // ventas totales de tiptap
      this.$store.dispatch("reportModule/getSales", {isAdmin: admin, id: admin ? null : this.$store.state.AppActiveUser.institution.id,dates: {from: this.from.toISOString().split("T")[0], to:this.to.toISOString().split("T")[0]}})
        .then(() => {
          this.setsalesChartData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProducts() {
      // Categorias y productos (grafico)

      if (!moduleDataList.isRegistered) {
        this.$store.registerModule("dataList", moduleDataList);
        moduleDataList.isRegistered = true;
      }
      if (!moduleDataListCategories.isRegistered) {
        this.$store.registerModule("dataListCategories",moduleDataListCategories);
        moduleDataListCategories.isRegistered = true;
      }

      this.$store.dispatch("dataList/getProducts",this.$store.state.AppActiveUser.institution.id)
        .then(() => {
          this.setProductsChartData();
        })
        .catch((error) => {
          console.log(error);
        });

    },
  },
  components: {
    VueApexCharts,
    StatisticsCard,
    CardAnalytics,
    dashboardAnalytics,
    StatisticsCardLine,
    analyticsData,
  },
  created() {

    if(!moduleDataListInstitutions.isRegistered) {
      this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
      moduleDataListInstitutions.isRegistered = true
    }

    if (!this.$store.state.reportModule) {
      if (!reportModule.isRegistered) {
        this.$store.registerModule("reportModule", reportModule);
        reportModule.isRegistered = true;
      }
    }

    if (this.$acl.check('manager'))
      this.getReverses()

    // USERS (cantidad de usuarios tiptap)
    if(this.$acl.check('admin') || this.$acl.check('superadmin')) {
      this.$store.dispatch("reportModule/getAllAccounts")
        .then(() => {
          this.setUsersChartData();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    //  Cantidad cafeterias
    if(this.$acl.check('admin')) {
      if(!moduleUserManagement.isRegistered) {
        this.$store.registerModule('userManagement', moduleUserManagement)
        moduleUserManagement.isRegistered = true
      }
      this.$store.dispatch("userManagement/getOwners")
        .then(()=> {
          this.$store.dispatch("dataListInstitutions/getInstitutions")
            .then(() => {
                this.setInstitutionCharData();
              })
        })
        .catch(error => console.log(error))


    }

    if(this.$acl.check('manager')) {
      this.$store.dispatch("reportModule/getAccounts",this.$store.state.AppActiveUser.institution.id)
        .then(() => {
          this.setUsersChartData();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // cantidad de instituciones que maneja el owner
    if (this.$acl.check('owner'))
      this.admin_statistics = this.$store.state.AppActiveUser.institutions ? this.$store.state.AppActiveUser.institutions.length : 1;

    // Total de dinero a pagar
    this.$store.dispatch("reportModule/getPendingPayments")
       .then(() => {
          this.setPaymentsChartData();
        })
        .catch((error) => {
          console.log(error);
        });

    if (this.$acl.check('admin')) {
      this.$store.dispatch("reportModule/getPendingPaymentsSchools")
       .then(() => {
          this.setPaymentsChartData();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // Total de dinero a cobrar
    if(!this.$acl.check('superadmin')) {
      this.$store.dispatch("reportModule/getRequestPay",this.$store.state.AppActiveUser.account.id)
       .then(() => {
          this.setChargeChartData();
        })
        .catch((error) => {
          console.log(error);
        });
      if(this.$acl.check('admin')) {
        this.$store.dispatch("reportModule/getPendingPaymentsSuperAdmin")
          .then(() => {
            this.setChargeSaChartData();
          })
          .catch(error => console.log(error))
      }
    } else {
      this.$store.dispatch("reportModule/getRequestPaySuperAdmin")
       .then(() => {
          this.setChargeChartData();
        })
        .catch((error) => {
          console.log(error);
        });
    }

    this.getSales(this.$acl.check('admin') || this.$acl.check('superadmin'))

    if (this.$acl.check('manager'))
      this.getProducts()

    if (this.type === "area") {
      // assign chart options
      this.chartOptions = Object.assign({}, chartConfigs.areaChartOptions);

      this.chartOptions["theme"] = {
        monochrome: {
          enabled: true,
          color: this.getHex(this.color),
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      };
    } else if (this.type === "line") {
      // Assign chart options
      this.chartOptions = JSON.parse(
        JSON.stringify(chartConfigs.lineChartOptions)
      );

      this.chartOptions.fill.gradient.gradientToColors = [
        this.gradientToColor(this.colorTo || this.color),
      ];
      this.chartOptions.colors = [this.getHex(this.color)];
    }
  },
};
</script>
<style lang="css" scoped>
  @media only screen and (max-device-width: 480px) {
    .responsive-fix {
      position: absolute;
      top: -32px;
      right: 10px;
    }
  }
</style>
